.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.full {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100vw;
  height: 75vh;
  gap: 10px;
}

.transcript {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 55%;
  height: 90%;
}

.arrow {
  font-size: 30px;
  color: grey;
}

.page_description {
  font-size: 10px;
  color: darkgrey;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

text {
  text-align: left;
  flex-grow: 1;
}

button {
  border-radius: 5px;
  transition-duration: 0.4s;
  padding: 10px;
  border: 1px none white;
  background-color: dimgray;
  cursor: pointer;
  width: 20%;
  color: white;
  width: 100px;
}

button:hover {
  background-color: hsl(122, 39%, 49%);
}

input {
  border: 1px none black;
  border-radius: 5px;
  padding: 5px;
  width: 50%;
  /* Set initial width */
  transition: width 0.5s ease-in-out;
  /* Add transition */
  height: 24px;
}

input:focus {
  width: 80%;
}

.audio {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 35%;
  height: 90%;
}

.frames {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.scrollable_window {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  overflow-y: scroll;
  width: 100%;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  transition: width 0.5s ease-in-out;
}

.scrollable_window:hover {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.column {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding: 5px 10px 5px 5px;
  gap: 5px;
  border-bottom: 1px none grey;
}
.column input {
  width: auto;
}
.column p {
  width: 75%;
  text-align: left;
}
.highlight {
  background-color: hsl(122, 39%, 49%);
  border: 1px none black;
  border-radius: 5px;
}

 .inputC {
   display: flex;
   width: 100%;
   justify-content: space-between;
   flex-grow: 1;
   align-items: center;
 }

 .fa-times {
   font-size: 20px;
   color: #000;
 }

 .innerFrame {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
 }




 .dropzone {
   margin-top: 50px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   color: #666;
   height: 80%;
   width: 100%;
   border: 2px dashed grey;
   border-radius: 20px;
   padding: 10px;
 }

 .innerDiv {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   margin-top: 10px;
   padding: 10px;
   gap: 10px;
 }
 .innerDiv h2 {
   margin-top: 0;
   text-align: center;
   width: 100%;
   overflow: hidden;
   font-weight: normal;
 }

 .innerDiv audio {
   width: 100%;
 }
.icon {
  width: 50px;
  height: 50px;
}

.iconC {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;
}
    .description p {
      font-size: 12px;
    }

.control {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: row;
  padding: 10px;
  z-index: 10;
}

.clicked {
  border-radius: 0px;
  transition-duration: 0.4s;
  padding: 10px;
  border: 1px none white;
  cursor: pointer;
  color: white;
  background-color: hsl(122, 39%, 49%);
}

.button {
  border-radius: 0px;
  transition-duration: 0.4s;
  padding: 10px;
  border: none;
  background-color: dimgray;
  cursor: pointer;
  width: 100px;
  color: white;
}

.history {
  display: flex;
  flex-direction: row;
  position:absolute;
  padding: 2px;
  top:0;
  left:0;
}
.history h2 {
  text-align: left;
  text-decoration: underline;
  padding: 4px;
  margin:0px;
}
.history p {
  text-align: left;
  padding: 4px;
  margin: 0px;
}
.history p:hover {
  text-decoration: underline;
}
.date {
  border: 1px white solid;
  width: 120px;
  height: 40px;
  overflow: hidden;
  /* Hide children */
  transition: all 0.3s ease;
  /* Smooth transition for expanding */

  /* Button-like appearance */
  background-color: #0094ff;
  color: white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}

/* Hover state */
.date:hover {
  min-width: 120px;
  width: auto;
  /* Expand to the width of the children */
  height: auto;
  /* Expand to the length of the children */
}

